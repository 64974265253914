
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import store from "../../store";

export const getPatientDetails = createAsyncThunk("admin/dashboard/patientlist", async (reqBody) => {
    const accessToken = (store.getState().loginSlice?.token); /// access token
    const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
    if (reqBody==null && reqBody==undefined ) reqBody = {
        "source": "user_views",
        token: accessToken,
        email:userMail,
        "condition": {
            "limit": 10,
            "skip": 0
        },
        "sort": {
            "type": "desc",
            "field": "createdon_datetime"
        },
       
        "searchcondition": {
            type:"patient"
        }
    }
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqBody),
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}api1/listingusers`, requestOptions);
    const res = await response.json();
    return res;
}
);





const adminDashboard = createSlice({
    name: 'teamManagement',
    initialState: {
        // message: null,
        loading: false,
        // success: false,
        // successmsg: "",
        patientList: null,
        patientListCount: null
    },
    reducers: {
    },
    extraReducers: {
        [getPatientDetails.pending]: (state, action) => {
            state.loading = true;
        },
        [getPatientDetails.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status === "success") {
                state.patientList = action.payload.results.res;
                // state.patientListCount = action.payload.count.count;
                // state.message = action.payload.respData.status;
             }
        },
        [getPatientDetails.rejected]: (state, action) => {
            state.loading = false;
        }
    }
})


export default adminDashboard.reducer;
export const { } = adminDashboard.actions;