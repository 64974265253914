import { Box, Button, Dialog, DialogActions, DialogContent, FormHelperText, IconButton, InputAdornment, InputLabel, Modal, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CloseIcon from '@mui/icons-material/Close';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { isValidYoutubeVideo, randomId } from '../../helper/helperFunctions';
import store from '../../store';
import { resetFormFieldError, resetTempFormUpdateData, setFieldNewValue } from '../formReducer';

//////////////////////////////////// Function to Generate Thumbnail Link ////////////////////////////////
const generateThumbnailLink = (inputLinkId) => {
    return `https://img.youtube.com/vi/${inputLinkId}/0.jpg`
}
///////////////////////////////////////////////////////////////////////////////////////////////////////

export const YoutubeTestimonial = ({ fielddata, value, setValue, getValues, errors, clearErrors, formId, setError }) => {

    const dispatch = useDispatch()
    const ref = useRef();
    const [playLink, setPlayLink] = useState('')
    const [open, setOpen] = useState(false);
    const [linkIds, setLinkIds] = useState([])
    const [thumbnailLink, setThumbnailLink] = useState([])
    const [customError, setCustomError] = useState(null);
    const [val, setVal] = useState(null)
    let formData = useSelector(state => state.formSlice.formData?.testimonialAddForm ? state.formSlice.formData.testimonialAddForm : null);
    const [openvideodeletemodal, SetOpenvideodeletemodal] = useState(false)


    store.subscribe(() => {
        const storeData = store.getState();
            
        if (Object.keys(storeData.formSlice.tempFormUpdateData).length > 0 && storeData.formSlice.tempFormUpdateData[formId] !== undefined && storeData.formSlice.tempFormUpdateData[formId][fielddata.name] !== undefined) {
            console.log("storing====>")
            setVal(storeData.formSlice.tempFormUpdateData[formId][fielddata.name])
        }

        //////////////////////////////////////////// Set Error from Outside ///////////////////////////////////////////////////////////
        if (Object.keys(storeData.formSlice.tempFormFieldError).length > 0 && storeData.formSlice.tempFormFieldError[formId] !== undefined && storeData.formSlice.tempFormFieldError[formId][fielddata.name] !== undefined) {
            console.log("storing====>")
            setCustomError(storeData.formSlice.tempFormFieldError[formId][fielddata.name])
        }
    })

    useEffect(() => {
        if (val !== null) {
            setValue(fielddata.name, val);
            dispatch(resetTempFormUpdateData({ formId, fieldName: fielddata.name }))
        }
    }, [val])

    //////////////////////////////////////// Effect the field error from outside //////////////////////////////
    useEffect(() => {
        if (customError !== null) {
            setError(fielddata.name, customError);
            dispatch(resetFormFieldError({ formId, fieldName: fielddata.name }))
        }
    }, [customError]);

    useEffect(() => {
        console.log("runnninggggggggggggggggggggggggg",getValues(fielddata.name))
        if(getValues(fielddata.name)===undefined){

        }

    }, [getValues(fielddata.name)])

    /////////////////////////// Set Default Value //////////////////////////
    useEffect(() => {
        if (fielddata.defaultYoutubeIds && fielddata.defaultYoutubeIds.length > 0) {
            if (fielddata.multiple == true) {
                //////////////////////////// Returns Promise /////////////////////////////////
                fielddata.defaultYoutubeIds.reduce(async (acc, id) => {
                    const isValidVideo = await isValidYoutubeVideo(id)
                    if (!isValidVideo.isValid) return acc
                    setLinkIds((prev) => [...prev, id])
                    setThumbnailLink((prev) => [...prev, generateThumbnailLink(`${id}`)])
                    return [...acc, {
                        id: id,
                        thumbnail_link: generateThumbnailLink(`${id}`)
                    }]
                }, []).then((formValue) => {
                    setValue(fielddata.name, formValue)
                })
                ////////////////////////////////////////////////////////////////////////////
            } else {
                setLinkIds([fielddata.defaultYoutubeIds[0]])
                setThumbnailLink([generateThumbnailLink(`${fielddata.defaultYoutubeIds[0]}`)])
                setValue(fielddata.name, { id: fielddata.defaultYoutubeIds[0], thumbnail_link: generateThumbnailLink(`${fielddata.defaultYoutubeIds[0]}`) })
            }
        }
    }, [getValues(fielddata.name)])

    //////////////////////// Update form data /////////////////////////////
    useEffect(() => {
        if (linkIds.length > 0 && thumbnailLink.length > 0 && linkIds.length === thumbnailLink.length) {
            if (fielddata.multiple == true) {
                const formValue = linkIds.reduce((acc, id) => {
                    return [...acc, {
                        id: id,
                        thumbnail_link: generateThumbnailLink(`${id}`)
                    }]
                }, [])
                setValue(fielddata.name, formValue)
            } else {
                setValue(fielddata.name, { id: linkIds[0], thumbnail_link: generateThumbnailLink(`${linkIds[0]}`) })
            }
        } else {
            setValue(fielddata.name, undefined)
        }
    }, [linkIds, thumbnailLink])

    //////////////////////////////// Check youtube video id and add /////////////////////////
    const addYoutubeLink = async () => {


        console.log("formData in yotube form", formData);

        console.log('ref value===>', ref.current.value)
        const inputLinkId = ref.current.value;

        if (ref.current.value == '') return

        if (linkIds.includes(inputLinkId)) {
            setError(fielddata.name, { type: "exists" });
            return;
        }

        const generatedThumbnailLink = generateThumbnailLink(inputLinkId);

        const isValidVideo = await isValidYoutubeVideo(inputLinkId)

        console.log('isValidImage===>', isValidVideo);

        if (isValidVideo.isValid) {
            if (fielddata.multiple === true) {
                setThumbnailLink((prev) => {
                    return [...prev, generatedThumbnailLink]
                })
                setLinkIds((prev) => {
                    return [...prev, inputLinkId]
                })
            } else {
                setThumbnailLink([generatedThumbnailLink])
                setLinkIds([inputLinkId])
            }

            ref.current.value = ''
        } else {
            console.log('isValidVideo===>', isValidVideo)
            setError(fielddata.name, { type: "invalid" })
        }



    }


    const checkvideoadd = () => {
        console.log("formData in yotube form", formData);
        if (formData != null) {
            if (formData && formData.video === undefined && formData.audio === undefined) {
                addYoutubeLink()
            } else {
                SetOpenvideodeletemodal(true)
            }
        } else {
            addYoutubeLink()
        }


    }
    const removeandadddata = () => {

        setValue(formData?.video !== undefined ? 'video' : 'audio',undefined);

        // dispatch(setFieldNewValue({ formId: 'testimonialAddForm', fieldName: formData?.video !== undefined ? 'video' : 'audio', value: undefined }))


        SetOpenvideodeletemodal(false)
        setTimeout(() => {
            addYoutubeLink()
        }, 1000);
    }

    //////////////////////////////// Remove youtube video ///////////////////////////////////
    const removeYoutubeLink = (index) => {
        console.log('removeYoutubeLink===>', index, thumbnailLink, linkIds)
        setThumbnailLink((prev) => {
            prev.splice(index, 1)
            return [...prev]
        })
        setLinkIds((prev) => {
            prev.splice(index, 1)
            return [...prev]
        })
    }

    ////////////////////////////// Play youtube video //////////////////////////////////////
    const playYoutubeLink = (index) => {
        console.log('linkIds[index]===>', linkIds[index])
        const generatePlayLink = `https://www.youtube.com/embed/${linkIds[index]}?autoplay=1&mute=1`
        setPlayLink(generatePlayLink)
        setOpen(true)
    }

    //////////////////////////// close video modal //////////////////////////////////////////
    const handleCloseModal = () => {
        setOpen(false)
        setPlayLink('')
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            <Modal
                open={openvideodeletemodal}
                onClose={() => SetOpenvideodeletemodal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modalBaseStyle'
            >
                <Box className='modalBaseBox smallModal'>
                    <p>The form cointains {formData?.video !== undefined ? 'Video' : 'Audio'} . Do you want to replace that media ? </p>
                    <div className='submitbuttonwrapper'>
                        <Button onClick={() => removeandadddata()}> Yes

                        </Button>
                        <Button onClick={() => SetOpenvideodeletemodal(false)} > No

                        </Button>
                    </div>



                    <IconButton className='modal_icon_button' onClick={() => SetOpenvideodeletemodal(false)}><CloseIcon /></IconButton>
                </Box>




            </Modal>
            <div className={fielddata.className}>
                {/* HEADING */}
                {fielddata.heading && <InputLabel ><div dangerouslySetInnerHTML={{ __html: fielddata.heading }}></div></InputLabel>}

                <div className='link_container'>
                    <div className='link_input_container'>
                        <TextField
                            label={fielddata.label}
                            className='linkid_enter'
                            inputRef={ref}
                            onChange={() => {
                                clearErrors(fielddata.name)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <p>https://www.youtube.com/watch?v=</p>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Button className='link_save_btn' variant='contained' onClick={() => checkvideoadd()}>Save</Button>
                    </div>

                    {/* ERROR */}
                    {errors[fielddata.name]?.type && <FormHelperText sx={{ color: 'red' }}>{fielddata.errorMessage[errors[fielddata.name].type]}</FormHelperText>}
                    <div className='link_thumbnail_container'>
                        {getValues(fielddata.name)!==undefined&&thumbnailLink.length > 0 && thumbnailLink.map((curLink, index) => {
                            return (
                                <div className='each_link_container' key={randomId() + index}>
                                    <img className='link_thumbnail' src={curLink} />
                                    <span className='linkid_remove' ><IconButton onClick={() => removeYoutubeLink(index)}><CloseIcon /></IconButton></span>
                                    <span className='linkid_play'><IconButton onClick={() => playYoutubeLink(index)}><PlayCircleOutlineIcon /></IconButton></span>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>

            {/* ------------------------------------ Dialog for Youtube Video Player --------------------------------- */}
            <Dialog
                open={open}
                keepMounted
                onClose={handleCloseModal}
                aria-describedby="alert-dialog-slide-description"
                className='dialog_box'
            >
                <DialogContent className='dialog_container'>
                    <IconButton className='dialog_close_btn' onClick={handleCloseModal}><CloseIcon /></IconButton>
                    <iframe className='youtube_video_player' width="420" height="315" allowFullScreen={true} src={playLink} />
                </DialogContent>
            </Dialog>
        </>
    )
}
