import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunkHelper } from "../helper/helperFunctions";


////////////////////////////////// Fetch Auto Complete List //////////////////////////////////////
export const fetchTableList = createAsyncThunk("table/fetchList", createAsyncThunkHelper);

/////////////////////////////////// Fetch Table List Count //////////////////////////////////////
export const fetchTableCount = createAsyncThunk("table/fetchCount", createAsyncThunkHelper);

////////////////////////////////// Delete Single User form Table //////////////////////////////////
export const deleteSingleTableData = createAsyncThunk("table/deleteSingleData", createAsyncThunkHelper)

//////////////////////////////// On Action Button Click Row /////////////////////////////
export const onRowActionButtonClicked = createAsyncThunk("table/actionBtnRow", createAsyncThunkHelper);

/////////////////////////////// On Notes Button Click /////////////////////////////////////////////////
export const onNotesButtonClick = createAsyncThunk("table/notes", async ({
  BASE_URL,
  endPoint,
  reqBody,
  tableId
}) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(reqBody)
  };
  console.log("process.env==>", process.env);
  const responseData = await fetch(BASE_URL + endPoint, requestOptions);
  const res = await responseData.json();
  return res;
})

export const onSubmittingNote = createAsyncThunk("table/notes/add", async ({
  BASE_URL,
  endPoint,
  reqBody,
  tableId
}) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(reqBody)
  };
  console.log("process.env==>", process.env);
  const responseData = await fetch(BASE_URL + endPoint, requestOptions);
  const res = await responseData.json();
  return res;
})

export const onSubmittingApi = createAsyncThunk("table/api", async ({
  BASE_URL,
  endPoint,
  reqBody,
  tableId
}) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(reqBody)
  };
  console.log("process.env==>", process.env);
  const responseData = await fetch(BASE_URL + endPoint, requestOptions);
  const res = await responseData.json();
  return res;
})

export const onSubmittingViewApi = createAsyncThunk("table/api/view", async ({
  BASE_URL,
  endPoint,
  reqBody,
  tableId
}) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(reqBody)
  };
  console.log("process.env==>", process.env);
  const responseData = await fetch(BASE_URL + endPoint, requestOptions);
  const res = await responseData.json();
  return res;
})
export const onSubmittingActionApi = createAsyncThunk("table/api/action", async ({
  BASE_URL,
  endPoint,
  reqBody,
  tableId
}) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(reqBody)
  };
  console.log("process.env==>", process.env);
  const responseData = await fetch(BASE_URL + endPoint, requestOptions);
  const res = await responseData.json();
  return res;
})

export const onSubmittingEditApi = createAsyncThunk("table/api/edit", async ({
  BASE_URL,
  endPoint,
  reqBody,
  tableId
}) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(reqBody)
  };
  console.log("process.env==>", process.env);
  const responseData = await fetch(BASE_URL + endPoint, requestOptions);
  const res = await responseData.json();
  return res;
})


const tableSlice = createSlice({
  name: "table",
  initialState: {
    tableData: {},
    snackbarMessage: "",
    loading: {},
    deleteLoading: {},
    count: {},
    tempEditData: {},
    tempViewData: {},
    tempRowData: {},
    customButtonClicked: {},
    reloadTableFlag: {},
    openModal: {},
    tempModalData: {},
    multipleSelectionList: {},
    tableFieldsData: {},
    actionLoading: {},
    actionData: {},
    deleteSingleDataSuccess: {},
    tableDefaultReqBody: {},
    tableConfig: {},
    tableCellClicked: {},
    customPagination: {},
    unmountTableFlag: {},
    rowLoading: {},
    searchEndpoint: {},
    customSorting: {},
    tempReqBody: {},
    pageState: {},
    seachFlag: {},
    notesLoading: {},
    note: {},
    notesLoaded: false,
    enableRowLoader: {},
    noteFieldsData: {},
    noteBtnClicked: 0,
    notesAddFormSubmissionState: {},
    notesAddFormSnackbarMessage: {},
    apiSubmissionLoading: {},
    apiBtnSubmissionState: {},
    apiBtnSubmissionResponse: {},
    apiSnackbarMessage: {},
    apiViewActionState: {},
    apiViewActionData: {},
    apiActionBtnSubmissionState: {},
    apiActionBtnSubmissionResponse: {},
    apiEditBtnSubmissionState: {},
    apiEditBtnSubmissionResponse: {},
  },
  reducers: {
    setEnableRowLoader(state, action) {
      if (action.payload.tableId && action.payload.rowId) {
        if (!state.enableRowLoader[action.payload.tableId]) state.enableRowLoader[action.payload.tableId] = {}
        state.enableRowLoader[action.payload.tableId][action.payload.rowId] = action.payload.load ? action.payload.load : false
      }
    },
    updateTableReqBodyForNotes(state, action) {
      if (state.noteFieldsData[action.payload.tableId] && state.noteFieldsData[action.payload.tableId]?.tableData?.reqBody) {
        state.noteFieldsData[action.payload.tableId].tableData.reqBody = action.payload.reqBody
      }
    },
    resetApiActionBtnSubmissionState(state, action) {
      if (state.apiActionBtnSubmissionState[action.payload.tableId]) state.apiActionBtnSubmissionState[action.payload.tableId] = 0
    },
    resetApiBtnSubmissionState(state, action) {
      if (state.apiBtnSubmissionState[action.payload.tableId]) state.apiBtnSubmissionState[action.payload.tableId] = 0
    },
    resetApiViewActionState(state, action) {
      if (state.apiViewActionState[action.payload.tableId]) state.apiViewActionState[action.payload.tableId] = 0
    },
    resetApiViewActionData(state, action) {
      if (state.apiViewActionData[action.payload.tableId]) delete state.apiViewActionData[action.payload.tableId]
    },
    resetApiEditActionState(state, action) {
      if (state.apiEditBtnSubmissionState[action.payload.tableId]) state.apiEditBtnSubmissionState[action.payload.tableId] = 0
    },
    resetNotesAddFormSubmissionState(state, action) {
      state.notesAddFormSubmissionState[action.payload.tableId] = 0
    },
    notesBtnClicked(state, action) {
      state.noteBtnClicked = action.payload
    },
    insertNoteFieldData(state, action) {
      if (!state.noteFieldsData[action.payload.tableId]) state.noteFieldsData[action.payload.tableId] = {}
      state.noteFieldsData[action.payload.tableId]['headers'] = action.payload.headers
      state.noteFieldsData[action.payload.tableId]['tableData'] = action.payload.tableData
      state.noteFieldsData[action.payload.tableId]['notesForKey'] = action.payload.notesForKey
      state.noteFieldsData[action.payload.tableId]['noteaddbody'] = action.payload.noteaddbody
      state.noteFieldsData[action.payload.tableId]['noteaddendpoint'] = action.payload.noteaddendpoint
      state.noteFieldsData[action.payload.tableId]['noteaddbaseurl'] = action.payload.noteaddbaseurl
    },
    resetNotesLoaded(state, action) {
      state.notesLoaded = false
    },
    setSeachFlag(state, action) {
      state.seachFlag[action.payload.tableId] = true;
    },
    clearSeachFlag(state, action) {
      state.seachFlag[action.payload.tableId] = false;
    },
    setPageState(state, action) {
      if (action.payload.tableId && action.payload.newPage) state.pageState[action.payload.tableId] = { newPage: action.payload.newPage, prevPage: action.payload.prevPage };
    },
    setTableDataAndCountAndBody(state, action) {
      console.log('action.payload==============================<<<<<<<<<<<<<', action.payload)
      if (Array.isArray(action.payload.data) && typeof (action.payload.count) == 'number' && action.payload.body) {
        state.tableData[action.payload.tableId] = action.payload.data;
        state.count[action.payload.tableId] = action.payload.count;
        state.tempReqBody[action.payload.tableId] = action.payload.body;
      }
    },
    resetTempBody(state, action) {
      if (state.tempReqBody[action.payload.tableId]) delete state.tempReqBody[action.payload.tableId];
    },
    setCustomSorting(state, action) {
      state.customSorting[action.payload.tableId] = action.payload.column
    },
    resetCustomSorting(state, action) {
      if (state.customSorting[action.payload.tableId]) delete state.customSorting[action.payload.tableId]
    },
    setTempSearchEndpoint(state, action) {
      state.searchEndpoint[action.payload.tableId] = action.payload.searchEndpoint;
    },
    deleteTempSearchEndpoint(state, action) {
      if (state.searchEndpoint[action.payload.tableId]) delete state.searchEndpoint[action.payload.tableId];
    },
    setTableConfig(state, action) {
      state.tableConfig[action.payload.tableId] = action.payload.tableConfig
    },
    unmountTable(state, action) {
      state.unmountTableFlag[action.payload.tableId] = true
    },
    resetUnmountTableFlag(state, action) {
      state.unmountTableFlag[action.payload.tableId] = false
    },
    rowCellClicked(state, action) {
      if (!state.tableCellClicked[action.payload.tableId]) state.tableCellClicked[action.payload.tableId] = {}
      state.tableCellClicked[action.payload.tableId][action.payload.column] = true
      state.tempRowData[action.payload.tableId] = action.payload.row;
    },
    resetRowCellClicked(state, action) {
      if (state.tableCellClicked[action.payload.tableId]) state.tableCellClicked[action.payload.tableId][action.payload.column] = false
    },
    setTableDefaultreqBody(state, action) {
      state.tableDefaultReqBody[action.payload.tableId] = { ...action.payload.reqBody };
    },
    /////////////////////////////////// Reset deleted single data success fully reset ///////////////////////////
    resetDeleteSingleDataFlag(state, action) {
      state.deleteSingleDataSuccess[action.payload.tableId] = 0;
    },
    ///////////////////////////////////// Spin Table Loader ////////////////////////////
    spinLoader(state, action) {
      if (state.loading[action.payload.tableId] !== true) state.loading[action.payload.tableId] = true;
    },
    stopLoader(state, action) {
      if (state.loading[action.payload.tableId] !== false) state.loading[action.payload.tableId] = false;
    },
    /////////////////////////// Reset Action Button Clicked ////////////////////////
    resetRowActionBtnClicked(state, action) {
      if (state.actionLoading[action.payload.tableId] !== undefined && state.actionLoading[action.payload.tableId][action.payload.buttonId] !== undefined) state.actionLoading[action.payload.tableId][action.payload.buttonId] = 0;
    },
    resetRowActionData(state, action) {
      delete state.actionData[action.payload.tableId][action.payload.buttonId]
    },
    /////////////////////////// Set table fields Data ///////////////////////
    setTableFieldsData(state, action) {
      state.tableFieldsData[action.payload.tableData.tableId] = { ...action.payload.tableData };
    },

    ////////////////////////////// For Multiple Selection ////////////////////
    setMultipleSeletedList(state, action) {
      state.multipleSelectionList[action.payload.tableId] = action.payload.rowIds.map(currentId => state.tableData[action.payload.tableId].find(cur => cur._id === currentId));
    },
    ///////////////////////////// Set Row Data ////////////////////////
    setTableRowData(state, action) {
      state.tempRowData[action.payload.tableId] = action.payload.row;
    },
    clearTablerowData(state, action) {
      delete state.tempRowData[action.payload.tableId];
    },
    //////////////////////////// For View ////////////////////////////
    setViewData(state, action) {
      state.tempViewData[action.payload.tableId] = action.payload.viewData;
      if (state.customButtonClicked[action.payload.tableId] === undefined) {
        state.customButtonClicked[action.payload.tableId] = {};
      }
      state.customButtonClicked[action.payload.tableId][action.payload.buttonId] = true;
    },
    clearViewData(state, action) {
      state.tempViewData = {};
      state.customButtonClicked = {};
    },
    ///////////////////////// For Modal ////////////////////////
    openModal(state, action) {
      console.log("From Modal", action.payload);
      if (state.openModal[action.payload.tableId] === undefined) {
        state.openModal[action.payload.tableId] = {}
      }
      if (state.tempModalData[action.payload.tableId] === undefined) {
        state.tempModalData[action.payload.tableId] = {}
      }
      state.openModal[action.payload.tableId][action.payload.buttonId] = true;
      state.tempModalData[action.payload.tableId][action.payload.buttonId] = action.payload.modalData;
    },
    closeModal(state, action) {
      state.openModal[action.payload.tableId][action.payload.buttonId] = false;
      delete state.tempModalData[action.payload.tableId][action.payload.buttonId];
    },
    ////////////////////////////// For Table reload /////////////////////////////////
    setReloadTableFlag(state, action) {
      state.reloadTableFlag[action.payload.tableId] = true;
    },
    resetReloadTableFlag(state, action) {
      state.reloadTableFlag[action.payload.tableId] = false;
    },
    /////////////////////////// For Edit ///////////////////////////////
    setTempEditData(state, action) {
      if (state.customButtonClicked[action.payload.tableId] === undefined) {
        state.customButtonClicked[action.payload.tableId] = {};
      }
      state.customButtonClicked[action.payload.tableId][action.payload.buttonId] = true;
      state.tempEditData.tableId = action.payload.tableId;
      state.tempEditData.editData = action.payload.editData;
      state.tempEditData.buttonId = action.payload.buttonId;

    },
    clearEditData(state, action) {
      state.tempEditData = {};
    },
    ///////////////////////////// For Custom butoon Clear ///////////////////////
    clearCustomButtonClicked(state, action) {
      console.log("Custom button clear", action.payload);
      state.customButtonClicked[action.payload.tableId][action.payload.buttonId] = false;
    },
    setCustomButtonClicked(state, action) {
      if (state.customButtonClicked[action.payload.tableId] === undefined) {
        state.customButtonClicked[action.payload.tableId] = {};
      }
      state.customButtonClicked[action.payload.tableId][action.payload.buttonId] = true;
    },
    //////////////////// For Table Data set ////////////////////////////////////
    setTableData(state, action) {
      state.tableData[action.payload.tableId] = action.payload.dataset;
    },
    setTableReqData(state, action) {
      state.tableData[action.payload.tableId].reqBody = action.payload.dataset;
    },
    ///////////////////////// Set Table Count ///////////////////////
    setTableCount(state, action) {
      console.log('paginationData===>', action.payload.paginationData)
      state.customPagination[action.payload.tableId] = action.payload.paginationData
    }
  },
  extraReducers: {
    /////////////////////////////////// Fetch Table List ///////////////////////////
    [fetchTableList.pending]: (state, action) => {
      state.loading[action.meta.arg.restResponseAttachment.tableId] = true;
    },
    [fetchTableList.fulfilled]: (state, action) => {
      console.log('first----------->', action)
      state.loading[action.payload.tableId] = false;
      if (action.meta.arg.recieveResponseParam && typeof (action.meta.arg.recieveResponseParam) == 'string') {
        state.tableData[action.payload.tableId] = action.payload[action.meta.arg.recieveResponseParam]
        state.customPagination[action.payload.tableId] = action.payload;
        delete state.customPagination[action.payload.tableId][action.meta.arg.recieveResponseParam]
        return;
      }
      if (action.payload.status === "error") {
        state.snackbarMessage = action.payload.msg; ///////////////// Verification Needed ////////////
      }
      if (action.payload.status === "success") {
        console.log('action.payload===>', action.payload.results.res)
        state.tableData[action.payload.tableId] = action.payload.results.res;
      }
    },
    [fetchTableList.rejected]: (state, action) => {
      state.loading[action.meta.arg.tableId] = false;
      state.snackbarMessage = "Something Went Wrong";
    },
    ///////////////////////////// Fetch Table Count ////////////////////////////////
    [fetchTableCount.fulfilled]: (state, action) => {
      console.log("From Table Count", action.payload);
      state.count[action.payload.tableId] = action.payload.count;
    },
    //////////////////////////// Delete Single User ///////////////////////////////
    [deleteSingleTableData.pending]: (state, action) => {
      state.deleteLoading[action.meta.arg.restResponseAttachment.tableId] = true;
      state.deleteSingleDataSuccess[action.meta.arg.restResponseAttachment.tableId] = 1;
    },
    [deleteSingleTableData.fulfilled]: (state, action) => {
      state.deleteLoading[action.meta.arg.restResponseAttachment.tableId] = false;
      if (action.payload.status === "success") {
        console.log(state.tableData[action.meta.arg.restResponseAttachment.tableId].findIndex(cur => cur._id === action.payload.id));
        state.tableData[action.payload.tableId].splice(state.tableData[action.payload.tableId].findIndex(cur => cur._id === action.payload.id), 1);
        state.count[action.payload.tableId] -= 1;
        state.deleteSingleDataSuccess[action.meta.arg.restResponseAttachment.tableId] = 2;
      }
      if (action.payload.status === "error") {
        state.snackbarMessage = "Something Went Wrong";
        state.deleteSingleDataSuccess[action.meta.arg.restResponseAttachment.tableId] = 3;
      }
    },
    [deleteSingleTableData.rejected]: (state, action) => {
      state.deleteLoading[action.meta.arg.restResponseAttachment.tableId] = false;
      state.deleteSingleDataSuccess[action.meta.arg.restResponseAttachment.tableId] = 3;
      state.snackbarMessage = "Something Went Wrong";
    },
    //////////////////////////////// On Row Action Button Click /////////////////////////////
    [onRowActionButtonClicked.pending]: (state, action) => {
      state.rowLoading[action.meta.arg.tableId] = action.meta.arg.rowId
      if (state.actionLoading[action.meta.arg.tableId] === undefined) {
        state.actionLoading[action.meta.arg.tableId] = {}
      }
      state.actionLoading[action.meta.arg.tableId][action.meta.arg.buttonId] = 1;
    },
    [onRowActionButtonClicked.fulfilled]: (state, action) => {
      if (state.rowLoading[action.meta.arg.tableId]) delete state.rowLoading[action.meta.arg.tableId]
      state.actionLoading[action.meta.arg.tableId][action.meta.arg.buttonId] = 2;
      if (state.actionData[action.meta.arg.tableId] === undefined) {
        state.actionData[action.meta.arg.tableId] = {};
      }
      state.actionData[action.meta.arg.tableId][action.meta.arg.buttonId] = action.payload.results.res;
      /////////// If results comes in array of multiple objects ///////////////////
      // if (action.meta.arg.isArray) {
      // }
      // /////////// If results comes in array of single objects ///////////////////
      // if (!action.meta.arg.isArray) {
      //   state.actionData[action.meta.arg.tableId][action.meta.arg.buttonId] = action.payload.results.res[0];
      // }
    },
    [onRowActionButtonClicked.rejected]: (state, action) => {
      if (state.rowLoading[action.meta.arg.tableId]) delete state.rowLoading[action.meta.arg.tableId]
      if (state.actionLoading[action.meta.arg.tableId] === undefined) {
        state.actionLoading[action.meta.arg.tableId] = {}
      }
      state.actionLoading[action.meta.arg.tableId][action.meta.arg.buttonId] = 3;
    },
    [onNotesButtonClick.pending]: (state, action) => {
      state.notesLoading[action.meta.arg.tableId] = true;
      if (!state.enableRowLoader[action.meta.arg.tableId]) state.enableRowLoader[action.meta.arg.tableId] = {}
      state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = true;
      state.notesLoaded = false
      if (state.note[action.meta.arg.tableId]) delete state.note[action.meta.arg.tableId]
    },
    [onNotesButtonClick.fulfilled]: (state, action) => {
      state.notesLoading[action.meta.arg.tableId] = false;
      state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = false;
      if (action.payload?.results?.res) {
        state.note[action.meta.arg.tableId] = action.payload.results.res
        state.notesLoaded = true
      }
    },
    [onNotesButtonClick.rejected]: (state, action) => {
      state.notesLoading[action.meta.arg.tableId] = false;
      state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = false;
      state.notesLoaded = false
      if (state.note[action.meta.arg.tableId]) delete state.note[action.meta.arg.tableId]
    },
    [onSubmittingNote.pending]: (state, action) => {
      state.notesAddFormSubmissionState[action.meta.arg.tableId] = 1;
      if (state.notesAddFormSnackbarMessage[action.meta.arg.tableId]) delete state.notesAddFormSnackbarMessage[action.meta.arg.tableId]

    },
    [onSubmittingNote.fulfilled]: (state, action) => {
      state.notesAddFormSubmissionState[action.meta.arg.tableId] = 2;
      if (action.payload?.status === "success") {
        state.notesAddFormSnackbarMessage[action.meta.arg.tableId] = action.payload.message ? action.payload.message : "Note Successfully Added";
      }
    },
    [onSubmittingNote.rejected]: (state, action) => {
      state.notesAddFormSubmissionState[action.meta.arg.tableId] = 3;

    },
    [onSubmittingApi.pending]: (state, action) => {
      state.apiSubmissionLoading[action.meta.arg.tableId] = true;
      state.apiBtnSubmissionState[action.meta.arg.tableId] = 1;
      if (!state.enableRowLoader[action.meta.arg.tableId]) state.enableRowLoader[action.meta.arg.tableId] = {}
      if (!Array.isArray(action.meta.arg.rowId)) state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = true;

    },
    [onSubmittingApi.fulfilled]: (state, action) => {
      state.apiSubmissionLoading[action.meta.arg.tableId] = false;
      if (!Array.isArray(action.meta.arg.rowId)) state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = false;
      if (action.payload?.status === "success") {
        state.apiBtnSubmissionState[action.meta.arg.tableId] = 2;
        state.apiSnackbarMessage[action.meta.arg.tableId] = action.payload.message ? action.payload.message : "Record Successfully Updated";
      }
      state.apiBtnSubmissionResponse[action.meta.arg.tableId] = action.payload ? action.payload : {}


    },
    [onSubmittingApi.rejected]: (state, action) => {
      if (!Array.isArray(action.meta.arg.rowId)) state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = false;
      state.apiSubmissionLoading[action.meta.arg.tableId] = false;
      state.apiBtnSubmissionState[action.meta.arg.tableId] = 3;
      state.apiBtnSubmissionResponse[action.meta.arg.tableId] = action.payload ? action.payload : {}
    },
    [onSubmittingViewApi.pending]: (state, action) => {
      state.apiViewActionState[action.meta.arg.tableId] = 1;
      if (!state.enableRowLoader[action.meta.arg.tableId]) state.enableRowLoader[action.meta.arg.tableId] = {}
      if (!Array.isArray(action.meta.arg.rowId)) state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = true;

    },
    [onSubmittingViewApi.fulfilled]: (state, action) => {
      if (!Array.isArray(action.meta.arg.rowId)) state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = false;
      if (action.payload?.status === "success") {
        state.apiViewActionState[action.meta.arg.tableId] = 2;
        state.apiViewActionData[action.meta.arg.tableId] = action.payload.results.res
      } else {
        state.apiViewActionState[action.meta.arg.tableId] = 3;
      }


    },
    [onSubmittingViewApi.rejected]: (state, action) => {
      if (!Array.isArray(action.meta.arg.rowId)) state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = false;
      state.apiViewActionState[action.meta.arg.tableId] = 3;
    },
    [onSubmittingActionApi.pending]: (state, action) => {
      state.apiSubmissionLoading[action.meta.arg.tableId] = true;
      state.apiActionBtnSubmissionState[action.meta.arg.tableId] = 1;
      if (!state.enableRowLoader[action.meta.arg.tableId]) state.enableRowLoader[action.meta.arg.tableId] = {}
      if (!Array.isArray(action.meta.arg.rowId)) state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = true;

    },
    [onSubmittingActionApi.fulfilled]: (state, action) => {
      state.apiSubmissionLoading[action.meta.arg.tableId] = false;
      if (!Array.isArray(action.meta.arg.rowId)) state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = false;
      if (action.payload?.status === "success") {
        state.apiActionBtnSubmissionState[action.meta.arg.tableId] = 2;
      }
      state.apiActionBtnSubmissionResponse[action.meta.arg.tableId] = action.payload ? action.payload : {}


    },
    [onSubmittingActionApi.rejected]: (state, action) => {
      if (!Array.isArray(action.meta.arg.rowId)) state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = false;
      state.apiSubmissionLoading[action.meta.arg.tableId] = false;
      state.apiActionBtnSubmissionState[action.meta.arg.tableId] = 3;
      state.apiActionBtnSubmissionResponse[action.meta.arg.tableId] = action.payload ? action.payload : {}
    },

    [onSubmittingEditApi.pending]: (state, action) => {
      state.apiEditBtnSubmissionState[action.meta.arg.tableId] = 1;
      if (!state.enableRowLoader[action.meta.arg.tableId]) state.enableRowLoader[action.meta.arg.tableId] = {}
      if (!Array.isArray(action.meta.arg.rowId)) state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = true;

    },
    [onSubmittingEditApi.fulfilled]: (state, action) => {
      if (!Array.isArray(action.meta.arg.rowId)) state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = false;
      if (action.payload?.status === "success") {
        state.apiEditBtnSubmissionState[action.meta.arg.tableId] = 2;
        state.apiEditBtnSubmissionResponse[action.meta.arg.tableId] = action.payload.results.res
      } else {
        state.apiEditBtnSubmissionState[action.meta.arg.tableId] = 3;
      }


    },
    [onSubmittingEditApi.rejected]: (state, action) => {
      if (!Array.isArray(action.meta.arg.rowId)) state.enableRowLoader[action.meta.arg.tableId][action.meta.arg.rowId] = false;
      state.apiEditBtnSubmissionState[action.meta.arg.tableId] = 3;
    },
  },
});

export const { setEnableRowLoader, resetApiEditActionState, updateTableReqBodyForNotes, resetApiActionBtnSubmissionState, resetApiViewActionData, resetApiViewActionState, resetApiBtnSubmissionState, resetNotesAddFormSubmissionState, notesBtnClicked, insertNoteFieldData, setPageState, clearSeachFlag, setSeachFlag, setTableDataAndCountAndBody, resetTempBody, setCustomSorting, resetCustomSorting, setTempSearchEndpoint, deleteTempSearchEndpoint, setTableConfig, unmountTable, resetUnmountTableFlag, setTableCount, resetRowCellClicked, rowCellClicked, setTableDefaultreqBody, setTableData, setTableReqData, resetDeleteSingleDataFlag, resetRowActionData, spinLoader, stopLoader, resetRowActionBtnClicked, setTableRowData, clearTablerowData, setMultipleSeletedList, setTableFieldsData, setCustomButtonClicked, clearViewData, setTempEditData, clearEditData, clearCustomButtonClicked, setReloadTableFlag, resetReloadTableFlag, openModal, closeModal, setViewData, resetNotesLoaded } = tableSlice.actions;

export default tableSlice.reducer;
