import { createSlice } from "@reduxjs/toolkit";



const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState: {
    loader: false,
    resolved: {}
  },
  reducers: {
    setLoader(state, action) {
      if (action.payload && action.payload.loader == true) state.loader = true
      else state.loader = false;
    },
    setResloved(state, action) {
      if (action.payload.path) {
        if (action.payload.resolve == true) state.resolved[action.payload.path] = true;
        else if (action.payload.resolve == false) state.resolved[action.payload.path] = false;
      }
    }
  },
  extraReducers: {}
})


export default layoutSlice.reducer;
export const { setLoader, setResloved } = layoutSlice.actions;