import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTokenFromStore } from "../../helper/helperFunctions";
import store from "../../store";

//////////////////////////// For user list ////////////////////////
export const getdataforadvolist = createAsyncThunk(
    "social-advo/list",
    async (reqBody) => {
      const accessToken = (store.getState().loginSlice?.token); /// access token
      const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
        if (reqBody == null && reqBody == undefined) {
            reqBody = {
                "source": "advo_views",
                "condition": {
                    "limit": 5,
                    "skip": 0
                },
                "sort": {
                    "type": "desc",
                    "field": "priority"
                },
                'token': accessToken,
                'email': userMail,
                "searchcondition": {

                }
            }
        }
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Authorization": getTokenFromStore(),
            },
            body: JSON.stringify(reqBody),
        };
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}api1/advolist`,
            requestOptions
        );
        console.log("Next 222 step", response)
        const res = await response.json();

        return res;
    }
);
//----------------------------------search---------------------------------//
export const getAdvoSeachList = createAsyncThunk("advo/search/list", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/advolist", requestOptions);
    const responseCount = await fetch(process.env.REACT_APP_API_URL + "api1/advolistcount", requestOptions);
    const respData = await responseData.json();
    const respCount = await responseCount.json();
    return { respData, respCount };
  });
//-----------------------------status update--------------------------//
export const advoStausUpdate = createAsyncThunk("social-media-advo/list/status", async (reqbody) => {
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const responseData = await fetch(process.env.REACT_APP_API_URL + "api1/advostatusupdate", requestOptions);
    const respData = await responseData.json();
    return respData;
});

//---------------API CALL FOR MULTI-DELETE----------------------//
export const multiDeleteadvo = createAsyncThunk("advo/multidelete", async (reqbody) => {
  const accessToken = (store.getState().loginSlice?.token); /// access token
  const userMail = (store.getState().loginSlice?.userInfo.email); /// for access token
    let req_body = {};
    req_body = { _ids: reqbody ,source: "socialmedia_advo_lists", email: userMail, token: accessToken };
    console.log("req_body==", req_body);
    const requestOptions = {
      method: 'POST',
      headers: { 'content-Type': 'application/json' },
      body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/multideleteadvo", requestOptions);
    const respdata = await response.json();
    return respdata;
  });
  
  //---------------API CALL FOR MULTI-EDIT----------------------//
  export const multiEditAdvo = createAsyncThunk("advo/multiedit", async (reqbody) => {  
    let req_body = {};
    req_body = { ...reqbody };
    console.log("req_body==", req_body);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(req_body)
    };
    console.log("process.env==>", process.env);
    const response = await fetch(process.env.REACT_APP_API_URL + "api1/multieditadvo", requestOptions);
    const respdata = await response.json();
    // console.log("respdata after endpoint fetch==>", respdata);
    return respdata;
  });

const socialMediaAdvoSlice = createSlice({
    name: 'socialMediaAdvoSlice',
    initialState: {
        loading: false,
        message: null,
        socialmediaadvodata: {},
        editDataForAdvo: {},
        statusChangeFlag: null,
        successmsg: "",
        success: false,
        statusChange: false,
        submitted: false,
        submitting: false,
        advoListCount: null,
        submitted: false,
        multiDeleteFlage: null,
        multiEditFlage: null,
    },
    reducers: {
        setAdvoEditData(state, action) {
            console.log("state from seteditdata==>", state, action);
            if (action.payload !== undefined && action.payload != null) {
                state.editDataForAdvo = action.payload;
            }
        },

    },
    extraReducers: {
        //--------------------FOR MULTI-DELETE blog----------------------------//
    [multiEditAdvo.pending]: (state, action) => {
        state.loading = true;
        state.multiDeleteFlage = true
      },
      [multiEditAdvo.fulfilled]: (state, action) => {
        console.log("action", action);
        if (action.payload.status === "success") {
          state.loading = false;
          state.successmsg = 'Deleted Successfully';
          state.multiDeleteFlage = false;
          state.submitting = false;
        }
      },
      [multiEditAdvo.rejected]: (state, action) => {
        state.loading = false;
        state.successmsg = 'Something went wrong';
        state.multiDeleteFlage = false;
      },
  
  
      //---------------------MULTI-EDIT blog------------------------//
      [multiDeleteadvo.pending]: (state, action) => {
        state.loading = true;
        state.multiEditFlage = true;
      },
      [multiDeleteadvo.fulfilled]: (state, action) => {
        console.log("action", action);
        if (action.payload.status === "success") {
          state.loading = false;
          state.successmsg = 'Updated Successfully';
          state.multiEditFlage = false;
          state.submitting = false;
        }
      },
      [multiDeleteadvo.rejected]: (state, action) => {
        state.loading = false;
        state.multiEditFlage = false;
        state.successmsg = 'Something went wrong';
      },
        ////////////////////////////////userlistasync for dashboard////////////////
        [getdataforadvolist.pending]: (state, action) => {
            state.loading = true;
        },
        [getdataforadvolist.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success") {
                state.socialmediaadvodata = action.payload.results.res;
                state.loading = false;
                state.submitting = false;

            }
        },
        [getdataforadvolist.rejected]: (state, action) => {
            state.loading = false;
        },
        //////////////////////////////////status update///////////////////////////////////
        [advoStausUpdate.pending]: (state, action) => {
            state.loading = true;
            state.statusChangeFlag = true;
        },
        [advoStausUpdate.fulfilled]: (state, action) => {
            console.log("action", action);
            if (action.payload.status === "success") {
                state.loading = false;
                state.statusChange = true;
                state.submitting = false;
                state.statusChangeFlag = false;
                state.message = action.payload.message;
            }
        },
        [advoStausUpdate.rejected]: (state, action) => {
            state.loading = false;
            state.statusChangeFlag = false;
        },
        //---------------------- search --------------------------//
    [getAdvoSeachList.rejected]: (state, action) => {
        state.loading = false;
      },
      [getAdvoSeachList.pending]: (state, action) => {
        state.loading = true;
        state.socialmediaadvodata = null;
        state.message = null;
      },
      [getAdvoSeachList.fulfilled]: (state, action) => {
        console.log('state', state,action);
        state.loading = false;
        if (action.payload.respData.status === 'success' && action.payload.respCount.status === 'success') { // payload:respCount:count // respData:results:res
          
          
          console.log('state222', state,action);
          state.socialmediaadvodata = action.payload.respData.results.res;
          state.advoListCount = action.payload.respCount.count;
          state.message = action.payload.respData.status;
        }
      },
    }
})

export const { setAdvoEditData } = socialMediaAdvoSlice.actions;

export default socialMediaAdvoSlice.reducer;